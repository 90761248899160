@import "../../../../node_modules/@otto-ec/global-frontend-settings/all";

.up-teaser-grey-background {
  background-color: $pl_color-grey100;
}

.meinup-container-margins {
  margin: 20px $pl_margin100 $pl_margin150;
}

.meinkonto-container-margins {
  margin-left: $pl_margin100;
  margin-right: $pl_margin100;
  margin-top: $pl_margin200;
}

.basket-container-margins {
  margin-top: $pl_margin100;
}

.margin-in-product-list {
  margin: $pl_margin75 $pl_margin100 $pl_margin100
}

.up_logo_red {
  fill: $pl_color-red100;
}

.bold {
  font-weight: bold;
}

#up-teaser-m-to-login-container a.to-login-link,
#up-teaser-s-to-login-container a.to-login-link,
#up-teaser-l-to-login-container a.to-login-link {
  all: unset;
}

#up-teaser-l-achievement-container a.achievement-link {
  all: unset
}

.blue-teaser {
  background-color: #374BC8;
}

.login-link {
  display: flex;
  all: unset;
}
